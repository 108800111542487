@use "../abstracts/mixins";

#side-panel .pane-frame {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-color: var(--panel-border-color);
  border-width: 1px;
}

#side-panel .pane-header {
  font-size: var(--font-size-sm);
  display: inline-flex;
}

#side-panel .pane-footer {
  font-size: var(--font-size-xs);
}

#side-panel .pane-header,
#side-panel .pane-footer {
  color: var(--link-dark-color);
  background-color: var(--panel-header-bg-color);
  border-radius: 0;
  border-width: 1px;
  padding: 0.5rem 1.25rem;
}

#side-panel .pane-header-title {
  display: inline-block;
  flex: 1;
  font-weight: bold;

  & > div {
    font-weight: normal;
  }
}

#side-panel .pane-header .expand,
#side-panel .pane-header .collapse {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding-left: 0.5rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

#side-panel .pane-content {
  font-size: var(--font-size-xs);
}

#side-panel .pane-content .pane {
  font-size: var(--font-size-xs);
  padding: 0.5rem 0.25rem;

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    padding-right: 1rem;
  }
}

#side-panel .pane-content > table {
  padding: 0 1.25rem;
}

#side-panel .pane-content tbody tr {
  &:not(:first-child) {
    border-top: 1px solid var(--panel-border-color);
  }
}

#side-panel .pane-header a,
#side-panel .pane-footer a,
#side-panel .pane-content a {
  @include mixins.link-dark;

  text-decoration: underline;
}

#side-panel .pane-header a {
  font-weight: bold;
}

#side-panel .pane-content a {
  font-weight: 600;
}

/**
 * Build Queue
 */
#buildQueue {
  margin-bottom: 1.25rem;
}

/**
 * Executors
 */
#executors th.pane {
  text-align: left;
}

/**
 * Build history
 */
.build-row {
  padding: 3px 4px;
}

.build-row.model-link-active {
  background: var(--very-light-grey) !important;
}

.build-row-cell {
  font-size: var(--font-size-xs);
}

.build-row-cell .pane.build-name {
  width: 30%;
  font-weight: bold;
  vertical-align: top;
}

.build-row-cell .pane.build-details {
  width: 50%;
}

.build-row-cell .pane.build-controls {
  width: 20%;
  text-align: right;
}

.build-row-cell .pane.build-details.block {
  width: 100%;
}

.build-row.multi-line .build-row-cell .pane.build-name.block {
  width: 100%;
}

.build-row-cell .pane.build-controls.block {
  width: 100%;
}

.build-row-cell .pane.build-name .build-icon,
.build-row-cell .pane.build-name .display-name {
  display: inline-block;
}

.build-row-cell .pane.build-name .build-icon {
  position: absolute;
  z-index: 1;
}

.build-row-cell .build-stop {
  display: inline-block;
  width: 30%;
}

.build-row-cell .build-badge {
  display: inline-block;
  text-align: right;
  width: 70%;
  padding: 2px 0;
}

.build-row-cell .build-badge > span {
  display: inline-block;
  max-width: 256px;
  padding: 0 1px;
  overflow: hidden;
}

.build-row-cell .build-badge > span + span {
  margin: 0 0 0 2px !important;
}

@media (min-width: 1170px) {
  .build-row-cell .build-badge > span {
    max-width: 296px;
  }
}

.build-row .build-name-controls .pane.build-name,
.build-row .build-details-controls .pane.build-details {
  width: 70%;
}

.build-row .build-row-cell .pane,
#side-panel .build-row .build-row-cell .pane {
  padding: 0 2px; /* Sync changes with func expandControlsTo50Percent in hudson-behavior.js */
  display: inline-block;
  overflow: hidden;
}

.build-row.multi-line .build-row-cell .block {
  display: block;
  overflow: auto;
}

.build-row.multi-line .build-row-cell .indent-multiline {
  margin-top: 5px;
}

.build-row.multi-line .build-row-cell .left-bar {
  position: absolute;
  top: 31px;
  bottom: 10px;
  left: 17px;
  border-left: 1px solid var(--medium-grey);
}

.build-row-cell .pane.build-name .display-name {
  margin-left: 20px;
  word-break: break-all;
}

.build-row-cell .indent-multiline {
  padding-left: 20px !important; /* Sync changes with func expandControlsTo50Percent in hudson-behavior.js */
}

.build-row.overflow-checked .build-row-cell {
  visibility: visible;
}

.jenkins-pane {
  &__information {
    text-align: center;
    line-height: 80px;
    background-color: var(--panel-header-bg-color);
    margin-top: 10px;
    font-weight: 600;
    border-radius: var(--form-input-border-radius);
  }

  .build-row {
    transition: opacity 0.2s ease;

    &-cell {
      padding: 4px 8px;
    }
  }

  &--loading .build-row {
    opacity: 0.5;
  }
}

.jenkins-pane__header--build-history {
  display: grid;
  grid-template-columns: auto 1fr auto;
  font-weight: bold !important;

  .jenkins-table__cell--tight {
    width: auto;
    margin-right: 1rem;
  }
}

@use "./abstracts/colors";
@use "./abstracts/theme";
@use "./base/display";
@use "./base/layout-commons";
@use "./base/spacing";
@use "./base/style";
@use "./base/typography";
@use "./base/visibility-utils";
@use "./base/yui-compatibility";
@use "./form/checkbox";
@use "./form/codemirror";
@use "./form/file-upload";
@use "./form/input";
@use "./form/layout";
@use "./form/radio";
@use "./form/reorderable-list";
@use "./form/search-bar";
@use "./form/select";
@use "./form/toggle-switch";
@use "./form/validation";
@use "./modules/app-bar";
@use "./modules/badges";
@use "./modules/breadcrumbs";
@use "./modules/buttons";
@use "./modules/buttons-deprecated";
@use "./modules/content-blocks";
@use "./modules/dropdowns";
@use "./modules/icons";
@use "./modules/modals";
@use "./modules/notifications";
@use "./modules/page-footer";
@use "./modules/page-header";
@use "./modules/panes-and-bigtable";
@use "./modules/section";
@use "./modules/side-panel-tasks";
@use "./modules/side-panel-widgets";
@use "./modules/spinner";
@use "./modules/progress-animation";
@use "./modules/row-selection-controller";
@use "./modules/table";
@use "./modules/tabs";
@use "./modules/tooltips";
@use "./pages/about";
@use "./pages/dashboard";
@use "./pages/icon-legend";
@use "./pages/manage-jenkins";
@use "./pages/plugin-manager";
@use "./pages/setupWizardFirstUser";
@use "./pages/setupWizardConfigureInstance";

html {
  // Ensure that the 1rem size is 16px and scales with the browser zoom
  // This is needed because bootstrap 3 overrides the font-size when loaded.
  //
  // It's set to 16px instead of 100% because using percentages would trigger
  // known browser bugs where elements with a font-family: monospace declaration
  // would not respect relative font-size rules.
  // The downside is that the page does not resize with the browser's font size,
  // only with the zoom level.
  font-size: 16px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}
